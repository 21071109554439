<template>
  <div id="scraper-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-card-list"></i>
          <span style="margin-left: 5px"> Scraper Online News </span>
        </b-card-title>
      </b-card-header>
      <!-- body component -->
      <b-card-body class="d-flex flex-wrap align-items-center pb-0">
        <!-- status filter -->
        <select
          v-model="status"
          id="Status"
          class="form-control mr-1"
          style="width: 10rem"
        >
          <option value="">Semua Status</option>
          <option value="1">Aktif</option>
          <option value="0">Nonaktif</option>
        </select>
        <!-- cakupan filter -->
        <select
          v-model="cakupan"
          id="Cakupan"
          class="form-control mr-1"
          style="width: 10rem"
        >
          <option value="">Semua Cakupan</option>
          <option value="nasional">Nasional</option>
          <option value="internasional">Internasional</option>
        </select>
        <!-- tier filter -->
        <select
          v-model="tier"
          id="Tier"
          class="form-control mr-1"
          style="width: 9rem"
        >
          <option value="">Semua Tier</option>
          <option value="0">Untier</option>
          <option value="1">Tier 1</option>
          <option value="2">Tier 2</option>
          <option value="3">Tier 3</option>
          <option value="4">Tier 4</option>
        </select>
        <!-- back button -->
        <b-button
          :variant="is_exist_description_only ? 'success' : 'outline-success'"
          class="ml-auto mr-1 btn-icon"
          v-b-tooltip.hover.top="'Hanya Tampilkan Yang Memiliki Keterangan'"
          @click="
            (is_exist_description_only = !is_exist_description_only), getData()
          "
        >
          <i class="bi bi-check-circle" style="font-size: 16px;"></i>
        </b-button>
        <!-- add scraper button -->
        <b-button
          variant="outline-info"
          class="mr-1"
          v-b-modal.modal-add-scraper
        >
          <feather-icon icon="PlusIcon" />
          <span style="margin-left: 2px">Tambah Scraper</span>
        </b-button>
        <!-- export scraper button -->
        <b-button variant="outline-success" class="mr-1" @click="exportScraper">
          <feather-icon icon="FileTextIcon" />
          <span style="margin-left: 2px">Export</span>
        </b-button>
        <!-- keyword filter -->
        <b-input-group class="input-group-merge" style="width: auto">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input placeholder="Masukkan kata kunci..." v-model="search" />
        </b-input-group>
      </b-card-body>
      <!-- loader component -->
      <loader-component v-if="is_loading" />
      <!-- table component -->
      <table-component
        v-else
        :data="table_data"
        :editItems="editScraper"
        :deleteItems="deleteScraper"
        :viewDetail="viewDetail"
        :updateLabel="updateLabel"
        :is_row_clicked="true"
        class="mt-1"
      />
    </b-card>
    <!-- add scraper modal component -->
    <add-scraper-component />
    <!-- edit scraper modal  component -->
    <edit-scraper-component :data="scraper_data" />
  </div>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import dataTable from "@/views/components/scraper/scraperTable.vue";
import loader from "@/views/components/loader.vue";
import addScraper from "@/views/components/scraper/addScraper.vue";
import editScraper from "@/views/components/scraper/editScraper.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BFormGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  VBModal,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  name: "Scraper",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    "table-component": dataTable,
    "loader-component": loader,
    "add-scraper-component": addScraper,
    "edit-scraper-component": editScraper,
  },
  data() {
    return {
      is_loading: true,
      search: this.$route.query.scraper || "",
      is_exist_description_only: false,
      status: "",
      tier: "",
      cakupan: "",
      web_status: "",
      page: 1,
      items: 10,
      table_data: {
        page_info: {
          limit: 10,
          page: 1,
          total: 0,
          _id: null,
        },
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "_id",
            label: "nama scraper",
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "domain",
            label: "domain",
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "status",
            label: "status",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "description",
            label: "Keterangan",
          },
          {
            key: "tier",
            label: "tier",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "cakupan",
            label: "cakupan",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "last_fixing",
            label: "terakhir fixing",
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "problem_classification",
            label: "klasifikasi masalah",
            thClass: "text-left",
          },
          {
            key: "solution",
            label: "solusi",
            thClass: "text-left",
          },
          {
            key: "actions",
            label: "aksi",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "5%", paddingRight: "2rem" },
          },
        ],
        items: [],
      },
      scraper_data: null,
      CancelToken: null,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    "$route.query"() {
      this.page = this.$route.query.page ? this.$route.query.page : 1;
      this.items = this.$route.query.items ? this.$route.query.items : 10;
      this.getData();
    },
    search() {
      if (this.$route.query.scraper) {
        let queries = JSON.parse(JSON.stringify(this.$route.query));
        if (queries.scraper) {
          delete queries.scraper;
        }
        if ($.param(this.$route.query) != $.param(queries)) {
          this.$router.replace({ query: queries });
        }
      }
      if (this.search.length > 2 || this.search.length == 0) {
        this.updateQuery("page", 1);
        this.getData();
      }
    },
    cakupan() {
      this.updateQuery("page", 1);
      this.getData();
    },
    tier() {
      this.updateQuery("page", 1);
      this.getData();
    },
    status() {
      this.updateQuery("page", 1);
      this.getData();
    },
    web_status() {
      this.updateQuery("page", 1);
      this.getData();
    },
  },
  methods: {
    getData() {
      if (this.CancelToken) {
        this.CancelToken.cancel();
      }
      this.CancelToken = axios.CancelToken.source();
      this.is_loading = true;

      let params = {
        page: this.page,
        limit: this.items,
        ...(this.is_exist_description_only
          ? { is_exist_description_only: this.is_exist_description_only }
          : {}),
      };
      if (this.search) {
        params.name = this.search;
      }
      if (this.tier) {
        params.tier = this.tier;
      }
      if (this.cakupan) {
        params.cakupan = this.cakupan;
      }
      if (this.status) {
        params.status = parseInt(this.status);
      }
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "scraper?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.CancelToken.token })
        .then((res) => {
          if (res.status == 200 && res.data.scraper.length > 0) {
            this.table_data.items = res.data.scraper;
            this.table_data.page_info = res.data.page_info[0];
          } else {
            this.table_data.items = [];
            this.table_data.page_info = {
              limit: 10,
              page: 1,
              total: 0,
              _id: null,
            };
            this.is_loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          if (this.table_data.items) {
            this.is_loading = false;
          }
        });
    },
    editScraper(id) {
      let filter_val = this.table_data.items.filter((el) => el._id == id);
      let scraper = filter_val[0];
      this.scraper_data = {
        new_id: scraper._id,
        _id: scraper._id,
        status: scraper.status,
        enable: scraper.enable,
        tier: scraper.tier || 0,
        cakupan: scraper.cakupan,
        media_name: scraper.media_name || [],
        domain: scraper.domain,
        description: scraper.description || "",
      };
      this.$bvModal.show("modal-edit-scraper");
    },
    deleteScraper(id, nama) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: `<strong>${id}</strong> Akan dihapus dari daftar scraper`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let api = process.env.VUE_APP_API_URL + "scraper/delete/" + id;
          useJwt.axiosIns
            .delete(api)
            .then(() => {
              this.getData();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Berhasil",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Scraper berhasil dihapus`,
                },
              });
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Gagal !",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: `Scraper gagal dihapus`,
                },
              });
            });
        }
      });
    },
    updateQuery(key, value) {
      let queries = JSON.parse(JSON.stringify(this.$route.query));
      queries[key] = value;
      if ($.param(this.$route.query) != $.param(queries)) {
        this.$router.replace({ query: queries });
      }
    },
    exportScraper() {
      let params = {};
      if (this.search) {
        params.name = this.search;
      }
      if (this.tier) {
        params.tier = parseInt(this.tier);
      }
      if (this.cakupan) {
        params.cakupan = this.cakupan;
      }
      if (this.status) {
        params.status = parseInt(this.status);
      }
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "scraper/export";
      if (query) {
        api += "?" + query;
      }
      window.open(api);

      // const link = document.createElement("a");
      // link.href = api;
      // link.target = "_blank";
      // link.setAttribute("download", "List Scraper");
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    },
    viewDetail(id) {
      this.$router.push({
        name: "detail-logs",
        query: { scraper: id },
      });
    },
    updateLabel(id, value) {
      let api = `${process.env.VUE_APP_API_URL}scraper/update-status/${id}`;
      useJwt.axiosIns
        .put(api, {
          status: value,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Berhasil",
              icon: "CheckCircleIcon",
              variant: "success",
              text: `Label Status Web Scraper berhasil diubah`,
            },
          });
          this.getData();
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Gagal !",
              icon: "AlertCircleIcon",
              variant: "danger",
              text: `Label Web Scraper gagal diubah`,
            },
          });
        });
    },
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>
