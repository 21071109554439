<template>
  <b-modal id="modal-edit-scraper" size="md" no-close-on-backdrop>
    <!-- custom modal title -->
    <template #modal-title>
      <div class="d-flex align-items-center">
        <feather-icon size="20" icon="EditIcon" class="mr-05" />
        <span class="fs-18">Edit Informasi Scraper</span>
      </div>
    </template>
    <!-- form container -->
    <loader-component v-if="is_loading" />
    <b-card no-body class="mb-0">
      <b-card-body>
        <validation-observer ref="scraperRules">
          <b-form>
            <!-- Nama -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Nama Scraper"
                rules="required"
              >
                <label for="nama"
                  >Nama Scraper <span class="text-danger">*</span>
                </label>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserCheckIcon" />
                  </b-input-group-prepend>

                  <b-form-input
                    v-model="data.new_id"
                    id="nama"
                    placeholder="Masukkan Nama Scraper"
                  />
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Status -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <label for="Status">
                  Status <span class="text-danger">*</span>
                </label>
                <select v-model="data.status" id="Status" class="form-control">
                  <option value="1">Aktif</option>
                  <option value="0">Tidak Aktif</option>
                </select>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Tier -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Tier"
                rules="required"
              >
                <label for="Tier">
                  Tier <span class="text-danger">*</span>
                </label>
                <select v-model="data.tier" id="Tier" class="form-control">
                  <option value="null" disabled selected>
                    Pilih Tier Scraper
                  </option>
                  <option value="0">Untier</option>
                  <option value="1">Tier 1</option>
                  <option value="2">Tier 2</option>
                  <option value="3">Tier 3</option>
                  <option value="4">Tier 4</option>
                </select>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Cakupan -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Cakupan"
                rules="required"
              >
                <label for="Cakupan">
                  Cakupan <span class="text-danger">*</span>
                </label>
                <select
                  v-model="data.cakupan"
                  id="Cakupan"
                  class="form-control"
                >
                  <option value="null" disabled selected>
                    Pilih Cakupan Scraper
                  </option>
                  <option value="nasional">Nasional</option>
                  <option value="internasional">Internasional</option>
                </select>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Domain -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Domain Scraper"
                rules="domain|required"
              >
                <label for="domain">
                  Domain Scraper <span class="text-danger">*</span>
                </label>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="GlobeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="data.domain"
                    id="domain"
                    placeholder="Masukkan Domain Scraper"
                    @input="domainFormatter"
                  />
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Keterangan -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Keterangan Scraper"
              >
                <label for="decription">
                  Keterangan <span class="text-danger">*</span>
                </label>
                <b-input-group class="input-group-merge">
                  <b-form-textarea
                    v-model="data.description"
                    id="description"
                    placeholder="Masukkan Keterangan"
                  />
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
        <div v-if="data && data.media_name && data.media_name.length > 0">
          <small>Nama Media Monalisa Terkait:</small>
          <div
            class="border rounded px-1 py-1 d-flex flex-wrap scroller"
            style="gap:5px;max-height: 10rem;"
          >
            <b-badge v-for="item in data.media_name" variant="success">
              {{ item }}
            </b-badge>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <!-- custom modal footer -->
    <template #modal-footer>
      <!-- button batal -->
      <b-button
        variant="outline-danger"
        size="sm"
        class="float-right mr-05"
        @click="cancelEditScraper"
      >
        Batal
      </b-button>
      <!-- button simpan -->
      <b-button
        variant="outline-info"
        size="sm"
        class="float-right"
        @click.prevent="editScraper"
      >
        Simpan
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal,
  VBModal,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BBadge,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, domain } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import floating_loader from "@/views/components/floatingLoader.vue";

export default {
  name: "editScraper",
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BBadge,
    BFormTextarea,
    "loader-component": floating_loader,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      is_loading: false,
      username_available: false,
      //   validation
      required,
      domain,
    };
  },
  methods: {
    editScraper() {
      this.$refs.scraperRules.validate().then((success) => {
        if (success) {
          this.is_loading = true;
          let api =
            process.env.VUE_APP_API_URL + "scraper/update/" + this.data._id;
          let params = {
            new_id: this.data.new_id,
            status: this.data.status,
            enable: this.data.enable,
            tier: parseInt(this.data.tier),
            cakupan: this.data.cakupan,
            domain: this.data.domain
              .trim()
              .toLowerCase()
              .replaceAll(/\s/g, ""),
            description: this.data.description,
          };
          useJwt.axiosIns
            .put(api, { data: params })
            .then(() => {
              this.$bvModal.hide("modal-edit-scraper");
              this.$parent.getData();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Berhasil",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Scraper berhasil diupdate`,
                },
              });
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Gagal",
                  icon: "XCircleIcon",
                  variant: "danger",
                  text: `Scraper gagal diupdate`,
                },
              });
            })
            .finally(() => {
              this.is_loading = false;
            });
        }
      });
    },
    cancelEditScraper() {
      this.$bvModal.hide("modal-edit-scraper");
    },
    domainFormatter() {
      this.data.domain = this.data.domain.toLowerCase().replaceAll(/\s/g, "");
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
<style>
.modal-title {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 20px !important;
  font-weight: bold;
}
</style>
