<template>
  <div>
    <b-table
      hover
      responsive
      :per-page="data.page_info.limit ? data.page_info.limit : 10"
      :fields="data.fields"
      :items="data.items"
      :filter="data.filter"
      sticky-header="700px"
      show-empty
      @row-clicked="onRowClicked"
    >
      <!-- show empty data -->
      <template #empty>
        <div class="alert alert-warning mt-1 py-1">
          <center>
            <p class="fs-14">
              <strong>Mohon maaf, data tidak tersedia!</strong>
              Silahkan Lakukan Pencarian dengan Parameter Lain
            </p>
          </center>
        </div>
      </template>

      <!-- show empty filtered -->
      <template #emptyfiltered>
        <div class="alert alert-warning mt-1 py-1">
          <center>
            <p class="fs-14">
              <strong>Mohon maaf, data tidak tersedia!</strong>
              Silahkan Lakukan Pencarian dengan Parameter Lain
            </p>
          </center>
        </div>
      </template>

      <!-- custom cell no -->
      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>

      <!-- custom cell scraper name -->
      <template #cell(_id)="data">
        <div class="d-flex align-items-center">
          {{ data.value }}
          <b-button
            @click="copyToClipboard(data.value)"
            variant="flat"
            class="btn-icon btn-link ml-2"
            size="10"
            v-b-tooltip.hover.v-dark="'Salin'"
          >
            <feather-icon
              :icon="
                is_copied && text_copied == data.value
                  ? 'CheckSquareIcon'
                  : 'CopyIcon'
              "
              size="18"
            />
          </b-button>
        </div>
      </template>

      <!-- custom cell status -->
      <template #cell(status)="data">
        <b-badge
          v-if="data.value == 1"
          variant="light-success"
          style="width: 7rem !important"
        >
          Aktif
        </b-badge>
        <b-badge v-else variant="light-danger" style="width: 7rem !important">
          Nonaktif
        </b-badge>
      </template>

      <!-- custom cell last_fixing -->
      <template #cell(last_fixing)="data">
        <b-badge v-if="data.value" variant="light-success">
          {{ data.value ? parseDate(data.value) : "-" }}
        </b-badge>
        <div v-else>
          -
        </div>
      </template>

      <!-- custom cell problem_classification -->
      <template #cell(problem_classification)="data">
        <div v-if="data.value">
          {{ data.value }}
        </div>
        <div v-else class="text-center">
          -
        </div>
      </template>

      <!-- custom cell solution -->
      <template #cell(solution)="data">
        <div v-if="data.value">
          {{ data.value }}
        </div>
        <div v-else class="text-center">
          -
        </div>
      </template>

      <!-- custom cell enable -->
      <template #cell(enable)="data">
        <b-badge
          v-if="data.value == 1"
          variant="light-success"
          style="width: 7rem !important"
        >
          Enabled
        </b-badge>
        <b-badge v-else variant="light-danger" style="width: 7rem !important">
          Disabled
        </b-badge>
      </template>

      <!-- custom cell description -->
      <template #cell(description)="data">
        <div
          v-if="data.value"
          class="scroller"
          style="max-height: 10rem;width: 15rem;"
        >
          <div v-if="data.item.description_updated_at" class="text-primary">
            <!-- <b-badge> -->
              [{{ parseDate(data.item.description_updated_at) }}]
            <!-- </b-badge> -->
          </div>
          {{ data.value }}
        </div>
        <div v-else class="text-center">
          -
        </div>
      </template>

      <!-- custom cell tier -->
      <template #cell(tier)="data">
        <b-badge
          variant="light-secondary"
          style="width: 5rem !important"
          v-if="data.value == 1"
        >
          Tier 1
        </b-badge>
        <b-badge
          variant="light-secondary"
          style="width: 5rem !important"
          v-else-if="data.value == 2"
        >
          Tier 2
        </b-badge>
        <b-badge
          variant="light-secondary"
          style="width: 5rem !important"
          v-else-if="data.value == 3"
        >
          Tier 3
        </b-badge>
        <b-badge
          variant="light-secondary"
          style="width: 5rem !important"
          v-else-if="data.value == 4"
        >
          Tier 4
        </b-badge>
        <b-badge
          variant="light-secondary"
          style="width: 5rem !important"
          v-else
        >
          Untier
        </b-badge>
      </template>

      <!-- custom cell cakupan -->
      <template #cell(cakupan)="data">
        <b-badge variant="light-secondary" style="width: 7rem !important">
          {{ data.value == "nasional" ? "Nasional" : "Internasional" }}
        </b-badge>
      </template>

      <!-- custom cell domain -->
      <template #cell(domain)="data">
        <a
          :href="redirectLink(data.value)"
          target="_blank"
          class="hoverable"
          v-if="data.value"
        >
          {{ data.value }}
        </a>
        <span v-else>-</span>
      </template>

      <!-- custom cell updated -->
      <template #cell(updated)="data">
        <div style="width: max-content !important">
          <div v-if="data.item.username">
            oleh "<strong> {{ data.item.username }} </strong>"
          </div>
          <br />
          {{
            parseDate(data.item.updated_at || data.item.updated_at_keterangan)
          }}
        </div>
      </template>

      <!-- custom cell actions -->
      <template #cell(actions)="data">
        <div class="d-flex">
          <b-button
            @click="editItems(data.item._id)"
            variant="outline-info"
            class="btn-icon mr-05"
            size="sm"
            v-b-tooltip.hover.v-dark="'Edit Scraper'"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            @click="deleteItems(data.item._id, data.item.nama)"
            variant="outline-danger"
            class="btn-icon"
            size="sm"
            v-b-tooltip.hover.v-dark="'Hapus Scraper'"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </template>
    </b-table>
    <pagination-component :data="data.page_info" />
  </div>
</template>

<script>
import helper from "@/helper/helper.js";
import pagination from "../pagination.vue";
import {
  BTable,
  BFormSelect,
  BPagination,
  BButton,
  VBTooltip,
  BBadge,
} from "bootstrap-vue";

export default {
  name: "scraperTable",
  components: {
    BTable,
    BFormSelect,
    BPagination,
    BButton,
    VBTooltip,
    BBadge,
    "pagination-component": pagination,
  },

  props: {
    is_row_clicked: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    data: Object,
    viewDetail: Function,
    editItems: Function,
    deleteItems: Function,
    fixedData: Function,
    updateLabel: Function,
  },
  data() {
    return {
      is_copied: false,
      text_copied: null,
    };
  },
  methods: {
    copyToClipboard(text) {
      this.text_copied = text;
      navigator.clipboard.writeText(text).then(() => {
        this.is_copied = true;
        setTimeout(() => {
          this.is_copied = false;
          this.text_copied = null;
        }, 1000);
      });
    },
    redirectLink(domain) {
      if (domain.includes("http")) {
        return domain;
      } else {
        return "https://" + domain;
      }
    },
    parseDate(date) {
      return helper.dateParser(date);
    },
    onRowClicked(item) {
      if (this.is_row_clicked) {
        this.viewDetail(item._id);
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>
